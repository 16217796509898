import Cookies from "js-cookie";
import { useFetchData, usePostData } from "../utils/ReactQueryHooks";
import { isData } from "../utils/HookResponse";

export const useLicenses = () =>
  useFetchData({
    key: "licenses",
    url: "licenses",
    data: {
      aId: Cookies.get("aId"),
    },
    options: {
      select: (data) => isData(data),
    },
    post: true,
  });

export const useLicensePools = (serials) =>
  useFetchData({
    key: "licensePools",
    url: "licenses/pools",
    post: true,
    data: serials?.map((i) => i.toLowerCase()),
    options: {
      enabled: false,
      select: (data) => isData(data),
    },
  });

export const useLicensePool = (serial) =>
  useFetchData({
    key: "licensePool",
    url: "licenses/pool",
    post: true,
    data: [serial?.toLowerCase()],
    options: {
      enabled: false,
      select: (data) => isData(data),
    },
  });

export const useLicenseNotificationUpdate = () =>
  usePostData({ url: "licenses/update-notification" });
