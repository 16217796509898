import { useState } from "react";

import Input from "../../components/ui/Form/Input";
import Skeleton from "../../components/ui/Preloader/Skeleton";
import Button from "../../components/ui/Button/Button";
import Table from "../../components/ui/Table/Table";

import { DateTime } from "luxon";

const FILTER_DEFAULT = {
  in: {
    from: "",
    to: "",
  },
  out: {
    from: "",
    to: "",
  },
};

const TableBounds = ({ serialData, isSerialDataLoading }) => {
  const columnsInOut = [
    {
      header: "Transaction Date",
      accessorKey: "created.at",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
      cell: (item) => item.getValue() || "1",
    },
  ];

  const [dateFilter, setDateFilter] = useState(FILTER_DEFAULT);

  const handleInOutData = (data, type) => {
    const mergeByDate = (data) => {
      const groupedData = {};

      data.forEach((item) => {
        const date = item.created.at.split("T")[0];
        const quantity = item.quantity || 1;

        if (!groupedData[date]) groupedData[date] = 0;

        groupedData[date] += quantity;
      });

      const mergedData = Object.keys(groupedData).map((date) => ({
        created: { at: date + "T00:00:00.000Z" },
        quantity: groupedData[date],
      }));

      return mergedData;
    };

    return mergeByDate(data).filter((item) => {
      if (
        (dateFilter[type].from !== "" &&
          item.created.at.slice(0, 10) < dateFilter[type].from) ||
        (dateFilter[type].to !== "" &&
          item.created.at.slice(0, 10) > dateFilter[type].to)
      ) {
        return false;
      }
      return true;
    });
  };

  const handleFilter = (event) => {
    switch (event.target.name) {
      case "inFrom":
        setDateFilter((prev) => ({
          ...prev,
          in: {
            ...prev.in,
            from: event.target.value,
          },
        }));
        break;
      case "inTo":
        setDateFilter((prev) => ({
          ...prev,
          in: {
            ...prev.in,
            to: event.target.value,
          },
        }));
        break;
      case "outFrom":
        setDateFilter((prev) => ({
          ...prev,
          out: {
            ...prev.out,
            from: event.target.value,
          },
        }));
        break;
      case "outTo":
        setDateFilter((prev) => ({
          ...prev,
          out: {
            ...prev.out,
            to: event.target.value,
          },
        }));
        break;

      default:
        break;
    }
  };

  const handleFilterReset = (type) => {
    setDateFilter((prev) => ({
      ...prev,
      [type]: {
        from: "",
        to: "",
      },
    }));
  };

  const getBoundSum = (data) => {
    return data.reduce((sum, item) => sum + (item.quantity || 1), 0);
  };

  return (
    <>
      {isSerialDataLoading && (
        <>
          <Skeleton />
          <Skeleton />
        </>
      )}
      {!isSerialDataLoading && serialData && (
        <div>
          <div className="flex gap-4">
            <p className="flex-grow self-center">
              <span className="text-lg font-semibold align-middle">
                Added/Purchased
              </span>
              <span className="bg-gray align-middle text-white text-xs font-bold mx-1 px-2 py-1 rounded-full">
                {getBoundSum(serialData.inboundAllocations)}
              </span>
            </p>
            <div className="flex-none">
              <Input
                type="date"
                name="inFrom"
                value={dateFilter.in.from}
                onChange={handleFilter}
                sm
              />
            </div>
            <p className="flex-none self-center">-</p>
            <div className="flex-none">
              <Input
                type="date"
                name="inTo"
                value={dateFilter.in.to}
                onChange={handleFilter}
                sm
              />
            </div>
            <Button
              solid
              secondary
              className="flex-none self-center"
              onClick={() => handleFilterReset("in")}
            >
              Clear
            </Button>
          </div>
          <Table
            data={handleInOutData(serialData.inboundAllocations, "in")}
            columns={columnsInOut}
            pageSize={5}
          />
          <div className="my-5">
            <hr />
          </div>
          <div className="flex gap-4">
            <p className="flex-grow self-center">
              <span className="text-lg font-semibold align-middle">
                Activation/Used
              </span>
              <span className="bg-gray align-middle text-white text-xs font-bold mx-1 px-2 py-1 rounded-full">
                {getBoundSum(serialData.outboundAllocations)}
              </span>
            </p>
            <div className="flex-none">
              <Input
                type="date"
                name="outFrom"
                value={dateFilter.out.from}
                onChange={handleFilter}
                sm
              />
            </div>
            <p className="flex-none self-center">-</p>
            <div className="flex-none">
              <Input
                type="date"
                name="outTo"
                value={dateFilter.out.to}
                onChange={handleFilter}
                sm
              />
            </div>
            <Button
              solid
              secondary
              className="flex-none self-center"
              onClick={() => handleFilterReset("out")}
            >
              Clear
            </Button>
          </div>
          <Table
            data={handleInOutData(serialData.outboundAllocations, "out")}
            columns={columnsInOut}
            pageSize={5}
          />
        </div>
      )}
    </>
  );
};

export default TableBounds;
