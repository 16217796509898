import { useEffect, useState } from "react";

import Button from "../../components/ui/Button/Button";
import Label from "../../components/ui/Label/Label";
import Input from "../../components/ui/Form/Input";
import Modal from "../../components/ui/Modal/Modal";

import { useLicensePools } from "../../hooks/useLicense";

import Hierarchy from "./Hierarchy";
import TableLicenses from "./TableLicenses";
import ViewLicense from "./ViewLicense";

const SERIAL_DEFAULT = {
  status: null,
  serialNumber: null,
  contactName: null,
  accountName: null,
  pool_balance_threshold: null,
  notification_contacts: null,
};

const License = () => {
  document.title = "Licenses";

  const [serials, setSerials] = useState([]);
  const [licenseFilter, setLicenseFilter] = useState("");

  const [serial, setSerial] = useState(SERIAL_DEFAULT);
  const [isOpenLicenseModal, setIsOpenLicenseModal] = useState(false);

  const [isSerialsEditable, setIsSerialsEditable] = useState(false);

  const {
    data: serialsData,
    isFetching: isSerialsDataLoading,
    refetch: serialsDataRefetch,
  } = useLicensePools(serials.map((item) => item.serialNumber));

  const handleSerialsData = (data) => {
    return (
      (data &&
        data.filter((item) => {
          if (licenseFilter != "") {
            return item.name
              .toLowerCase()
              .includes(licenseFilter.toLowerCase());
          }

          return true;
        })) ||
      []
    );
  };

  const handleCloseModal = () => {
    setIsOpenLicenseModal(false);
  };

  const handleSelectAccount = (data) => {
    if (data.length === 2 && data.every((item) => item !== undefined)) {
      setSerials(data[1]);
      setIsSerialsEditable(data[0]);
    }
  };

  useEffect(() => {
    if (serials.length > 0) {
      serialsDataRefetch();
    }
  }, [serials]);

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-3">
          <Hierarchy handleSelectAccount={handleSelectAccount} />
        </div>
        <div className="col-span-12 lg:col-span-9">
          <div className="grid md:grid-cols-2 items-center">
            <div className="flex items-center justify-center md:justify-start ">
              <Label xl>Licenses</Label>
            </div>
            <div
              className={`flex justify-center md:justify-end gap-2 pt-2 md:pt-0 items-center ${
                serials?.length > 0 || "invisible"
              }`}
            >
              <Input
                md
                placeholder="Search Licenses..."
                value={licenseFilter}
                onChange={(e) => setLicenseFilter(e.target.value)}
              />
              <Button
                solid
                secondary
                isLoading={isSerialsDataLoading ? true : false}
                onClick={() => {
                  setLicenseFilter("");
                  serialsDataRefetch();
                }}
              >
                Refresh License Pool Balances
              </Button>
            </div>
          </div>
          <TableLicenses
            serialsData={
              serials.length > 0 ? handleSerialsData(serialsData) : []
            }
            isSerialsDataLoading={isSerialsDataLoading}
            handleSelectLicense={(serial) => {
              setSerial(serials.find((item) => item.serialNumber == serial));
              setIsOpenLicenseModal(true);
            }}
          />
        </div>
      </div>
      {serial?.serialNumber && (
        <Modal
          xl
          open={isOpenLicenseModal}
          showClose={false}
          onClose={handleCloseModal}
          title="View License"
        >
          <ViewLicense
            serial={serial}
            setSerials={setSerials}
            isEditable={isSerialsEditable}
            isOpenLicenseModal={isOpenLicenseModal}
          />
        </Modal>
      )}
    </>
  );
};

export default License;
