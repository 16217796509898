import Error from "../Label/Error";

const Textarea = ({
  label,
  sublabel,
  validation,
  error,
  placeholder,
  ...rest
}) => {
  const textareaClass =
    "block w-full px-2 py-3 rounded-md border-0 ring-1 ring-inset placeholder:text-gray-400 focus:ring-inset sm:text-sm";

  return (
    <>
      <label className="block text-sm">
        {label}
        {sublabel !== "" && (
          <label className="float-end text-gray-500 text-xs">{sublabel}</label>
        )}
      </label>
      <div className="mt-1">
        <textarea
          aria-label={validation?.name}
          placeholder={placeholder}
          className={`${textareaClass} ${
            error ? "ring-red-500" : "ring-gray-300"
          }`}
          {...validation?.register(validation.name, validation.rules)}
          {...rest}
        />
        {error && <Error>{error}</Error>}
      </div>
    </>
  );
};

Textarea.defaultProps = {
  label: "",
  sublabel: "",
  placeholder: "",
  error: null,
  validation: null,
};

export default Textarea;
