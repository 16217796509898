import Error from "../Label/Error";

const FileInput = ({ label, guide, validation, error, ...rest }) => {
  const inputClass =
    "block w-full p-2 rounded-md border-0 ring-1 ring-inset sm:text-xs";

  return (
    <>
      <label className="block text-sm" htmlFor={validation?.name}>
        {label}
      </label>
      <input
        id={validation?.name}
        aria-label={validation?.name}
        className={`${inputClass} ${error ? "ring-red-500" : "ring-gray-300"}`}
        type="file"
        {...validation?.register(validation.name, validation.rules)}
        {...rest}
      />
      {error && <Error>{error}</Error>}
      <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">{guide}</p>
    </>
  );
};

FileInput.defaultProps = {
  label: "",
  guide: "",
  error: null,
  validation: null,
};

export default FileInput;
