import clsx from "clsx";
import { inputSize } from "./inputTheme";
import Error from "../Label/Error";

const Input = ({
  label,
  sublabel,
  validation,
  placeholder,
  type,
  error,
  innerRef,

  className,

  sm,
  md,
  lg,

  ...rest
}) => {
  const inputClass =
    "block w-full px-2 rounded-md border-0 ring-1 ring-inset placeholder:text-gray-400 focus:ring-inset sm:text-sm";

  const buildSizeClass = () => {
    if (sm) return inputSize.sm;
    if (md) return inputSize.md;
    if (lg) return inputSize.lg;
    return inputSize.lg;
  };

  const buildClassName = () => {
    return clsx(
      inputClass,
      buildSizeClass(),
      className,
      error ? "ring-red-500" : "ring-gray-300"
    );
  };

  return (
    <div>
      <label className="block text-sm">
        {label}
        {sublabel !== "" && (
          <label className="float-end text-gray-500 text-xs">{sublabel}</label>
        )}
      </label>

      <div className="mt-1">
        <input
          type={type}
          aria-label={validation?.name}
          placeholder={placeholder}
          ref={innerRef}
          className={buildClassName()}
          {...validation?.register(validation.name, validation.rules)}
          {...rest}
        />
        {error && <Error>{error}</Error>}
      </div>
    </div>
  );
};
Input.defaultProps = {
  label: "",
  sublabel: "",
  placeholder: "",
  type: "text",
  error: null,
  validation: null,
  innerRef: null,

  className: "",

  sm: false,
  md: false,
  lg: false,
};

export default Input;
