import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { useState } from "react";
import Button from "../Button/Button";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Skeleton from "../Preloader/Skeleton";

const TABLE_CLASSES = {
  container: "relative flex flex-col bg-clip-border bg-white w-full",
  tableContainer: "border overflow-x-scroll scrollbar-thin mt-4 px-0",
  table: "w-full min-w-max table-auto text-left text-sm",
  th: "cursor-pointer border-y border-blue-gray-100 bg-gray-muted p-3",
  thContainer:
    "text-xs antialiased text-blue-gray-900 flex items-center justify-between gap-2 font-semibold opacity-70 leading-none",
  td: "p-3 border-b border-blue-gray-50",
  footerContainer:
    "grid md:grid-cols-2 items-center border-t border-blue-gray-50 pt-4 p-3",
  footerPage: "flex items-center justify-center md:justify-start gap-1 text-sm",
  footerBtn: "flex justify-center md:justify-end gap-2 pt-2",
};

const Table = ({ data, columns, isLoading, pageSize }) => {
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
  });

  return (
    <div className={TABLE_CLASSES.container}>
      <div className={TABLE_CLASSES.tableContainer}>
        <table className={TABLE_CLASSES.table}>
          <thead className={TABLE_CLASSES.thead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className={TABLE_CLASSES.th}
                  >
                    {header.isPlaceholder ? null : (
                      <p className={TABLE_CLASSES.thContainer}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {!header.column.getIsSorted() && <FaSort />}
                        {
                          { asc: <FaSortUp />, desc: <FaSortDown /> }[
                            header.column.getIsSorted() ?? null
                          ]
                        }
                      </p>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td className="p-5 text-gray" colSpan={columns.length}>
                  <Skeleton />
                </td>
              </tr>
            )}
            {!isLoading &&
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={TABLE_CLASSES.tr}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className={TABLE_CLASSES.td}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {!isLoading && (
        <div className={TABLE_CLASSES.footerContainer}>
          <div className={TABLE_CLASSES.footerPage}>
            <div>Page</div>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount().toLocaleString()}
          </div>
          <div className={TABLE_CLASSES.footerBtn}>
            <Button
              onClick={() => {
                table.setPageIndex(0);
              }}
              disabled={!table.getCanPreviousPage()}
              solid
              gray
            >
              First
            </Button>
            <Button
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
              solid
              gray
            >
              Prev
            </Button>
            <Button
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
              solid
              gray
            >
              Next
            </Button>
            <Button
              onClick={() => {
                table.setPageIndex(table.getPageCount() - 1);
              }}
              disabled={!table.getCanNextPage()}
              solid
              gray
            >
              Last
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  isLoading: false,
  data: [],
  columns: [],
  pageSize: 10,
};

export default Table;
