import Alert from "../../components/ui/Alert/Alert";
import Button from "../../components/ui/Button/Button";
import Table from "../../components/ui/Table/Table";

const TableLicenses = ({
  serialsData,
  isSerialsDataLoading,
  handleSelectLicense,
}) => {
  const columns = [
    {
      header: "License Name",
      accessorKey: "name",
    },
    {
      header: "Pool ID",
      accessorKey: "uuid",
    },
    {
      header: "Pool Balance",
      accessorKey: "balance",
    },
    {
      header: "Actions",
      cell: ({ row }) =>
        row.original.uuid && (
          <div>
            <Button
              onClick={() => handleSelectLicense(row.original.uuid)}
              solid
              primary
              sm
            >
              View
            </Button>
          </div>
        ),
    },
  ];

  return (
    <>
      {!serialsData?.length > 0 && (
        <Alert className="mt-2" warning>
          <p className="mt-2">
            If there&apos;s no balance displayed, you may have purchased a
            different type of license pool. Please contact our support team if
            you have any questions.
          </p>
        </Alert>
      )}
      <Table
        data={
          serialsData?.length > 0
            ? serialsData
            : [{ name: "No license available." }]
        }
        columns={columns}
        isLoading={isSerialsDataLoading ? true : false}
      />
    </>
  );
};

export default TableLicenses;
