import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const Home = () => {
  return (
    <>
      {Cookies.get("prevLocation") && (
        <Navigate
          to={
            (Cookies.get("prevLocation") != "/" &&
              Cookies.get("prevLocation")) ||
            (Cookies.get("user_permission").split("-").includes("firmware") &&
              "/firmware") ||
            (Cookies.get("user_permission").split("-").includes("license") &&
              "/license") ||
            (Cookies.get("user_permission") == "" && "/restricted")
          }
          replace={true}
        />
      )}
    </>
  );
};

export default Home;
