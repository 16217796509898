import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Cookies from "js-cookie";
import { FaQuestionCircle } from "react-icons/fa";

import Skeleton from "../../components/ui/Preloader/Skeleton";
import LabelData from "../../components/ui/Label/LabelData";
import Input from "../../components/ui/Form/Input";
import MultiInput from "../../components/ui/Form/MultiInput";
import Button from "../../components/ui/Button/Button";
import Tooltip from "../../components/ui/Tooltip/Tooltip";
import { MESSAGES } from "../../constants/messages";

import {
  useLicenseNotificationUpdate,
  useLicensePool,
} from "../../hooks/useLicense";
import TableBounds from "./TableBounds";

const FORM_DEFAULT = {
  threshold: null,
  contacts: null,
};

const ViewLicense = ({
  serial,
  setSerials,
  isEditable,
  isOpenLicenseModal,
}) => {
  const [contactTags, setContactTags] = useState(
    (serial.notification_contacts && serial.notification_contacts.split(";")) ||
      []
  );
  const [formData, setFormData] = useState(FORM_DEFAULT);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: serialData,
    isFetching: isSerialDataLoading,
    refetch: serialDataRefetch,
  } = useLicensePool(serial.serialNumber);

  const {
    mutate: notificationUpdateMutate,
    data: notificationUpdateData,
    isSuccess: notificationUpdateSuccess,
    isError: notificationUpdateError,
  } = useLicenseNotificationUpdate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const handleResetForm = () => {
    setValue("threshold", serial.pool_balance_threshold);
    setValue(
      "contacts",
      (serial.notification_contacts &&
        serial.notification_contacts.split(";")) ||
        []
    );
    setContactTags(
      (serial.notification_contacts &&
        serial.notification_contacts.split(";")) ||
        []
    );
  };

  const handleNotificationSubmit = (data) => {
    setIsSubmitting(true);
    setFormData({
      threshold: data.threshold,
      contacts: data.contacts,
    });

    notificationUpdateMutate({
      aId: Cookies.get("aId"),
      serial: serial.serialNumber,
      contacts: data.contacts,
      threshold: data.threshold,
    });
  };

  useEffect(() => {
    if (serial.length !== 0) {
      serialDataRefetch();
    }
  }, [serial]);

  useEffect(() => {
    handleResetForm();

    if (!isOpenLicenseModal) {
      setContactTags([]);
      reset();
    }
  }, [serial, isOpenLicenseModal]);

  useEffect(() => {
    if (notificationUpdateSuccess || notificationUpdateError) {
      if (
        notificationUpdateSuccess &&
        notificationUpdateData.data?.status == "success"
      ) {
        setSerials((prev) =>
          prev.map((item) => {
            if (item.serialNumber == serial.serialNumber) {
              item.pool_balance_threshold = formData.threshold;
              item.notification_contacts = formData.contacts.join(";");
            }
            return item;
          })
        );
      } else {
        setFormData(FORM_DEFAULT);
      }

      setIsSubmitting(false);
    }
  }, [notificationUpdateSuccess, notificationUpdateError]);

  return (
    <div className="grid lg:grid-cols-3 text-sm divide-x">
      <div className="lg:pr-4">
        {isSerialDataLoading && <Skeleton />}
        {!isSerialDataLoading && serialData && serialData.pools && (
          <>
            <LabelData label="Account">{serial.accountName}</LabelData>
            <LabelData label="License Name">
              {serialData.pools[0].name}
            </LabelData>
            <LabelData label="Contact">{serial.contactName}</LabelData>
            <LabelData label="Status">{serial.status}</LabelData>
            <LabelData label="Pool ID">{serialData.pools[0].uuid}</LabelData>
            <LabelData label="Audinate Product">
              {serialData.pools[0].productSKU.sku}
            </LabelData>
            <LabelData label="Pool Balance">
              {serialData.pools[0].balance}
            </LabelData>
            <hr />
            {(Cookies.get("user_permission")
              .split("-")
              .includes("licenseThreshold") &&
              isEditable && (
                <form onSubmit={handleSubmit(handleNotificationSubmit)}>
                  <div className="mt-4">
                    <Input
                      label={
                        <Tooltip message={MESSAGES.HELP.NOTIFICATION_THRESHOLD}>
                          <div className="flex flex-nowrap">
                            Notification Threshold
                            <FaQuestionCircle className="text-gray-500 text-xs self-center ml-1" />
                          </div>
                        </Tooltip>
                      }
                      type="number"
                      step="1"
                      min="0"
                      placeholder="Enter Notification Threshold"
                      validation={{
                        name: "threshold",
                        register,
                        rules: {
                          required: "Notification Threshold is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Only whole numbers are allowed",
                          },
                        },
                      }}
                      error={errors.threshold?.message}
                    />
                  </div>
                  <div className="mt-4">
                    <Controller
                      name="contacts"
                      control={control}
                      defaultValue={contactTags}
                      rules={{
                        validate: (value) =>
                          value.every((tag) =>
                            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tag)
                          ) || "All contacts must be valid email addresses",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <MultiInput
                          label="Notification Contacts"
                          placeholder="Add an email"
                          value={value}
                          onChange={(newTags) => {
                            setContactTags(newTags);
                            onChange(newTags);
                          }}
                          error={errors.contacts?.message}
                        />
                      )}
                    />
                    {contactTags.length === 0 && (
                      <label className="text-info text-xs">
                        If notification contacts are not provided, all
                        notifications will be sent to the License Contact above.
                      </label>
                    )}
                  </div>
                  <div className="mt-4 md:flex justify-end gap-2">
                    <Button
                      type="submit"
                      solid
                      primary
                      isLoading={isSubmitting}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={handleResetForm}
                      solid
                      secondary
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              )) || (
              <>
                <div className="mt-4"></div>
                <LabelData label="Notification Threshold">
                  {serial.pool_balance_threshold || "-"}
                </LabelData>
                <LabelData label="Notification Contacts">
                  {(serial.notification_contacts &&
                    serial.notification_contacts
                      .split(";")
                      .map((email, key) => (
                        <span key={key} className="block">
                          {email}
                        </span>
                      ))) ||
                    "-"}
                </LabelData>
              </>
            )}
          </>
        )}
      </div>
      <div className="lg:col-span-2 lg:pl-4">
        <TableBounds
          serialData={serialData}
          isSerialDataLoading={isSerialDataLoading}
        />
      </div>
    </div>
  );
};

export default ViewLicense;
