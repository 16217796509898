import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import Error from "../Label/Error";

const Select = ({
  label,
  sublabel,
  options,
  error,
  placeholder,
  isLoading,
  isCreatable,
  setValue,
  field,
}) => {
  const selectClass =
    "!rounded-md py-1 ring-1 !border-0 sm:text-sm ring-inset ";

  const customStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      boxShadow: state.isFocused ? "0 0 0 2px black" : "",
    }),
    placeholder: (value) => ({
      ...value,
      color: "#BDBDBD",
    }),
  };

  const customClassNames = {
    control: () => `${selectClass} ${error ? "ring-red-500" : "ring-gray-300"}`,
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "gray",
      primary25: "lightgray",
    },
  });

  return (
    <>
      <label className="block text-sm">
        {label}
        {sublabel !== "" && (
          <label className="float-end text-gray-500 text-xs">{sublabel}</label>
        )}
      </label>
      <div className="mt-1">
        {!isCreatable && (
          <ReactSelect
            placeholder={placeholder}
            options={options}
            styles={customStyle}
            classNames={customClassNames}
            theme={customTheme}
            onChange={(e) => {
              setValue(field.name, e);
              field.onChange(e);
            }}
            isLoading={isLoading}
            {...field}
          />
        )}

        {isCreatable && (
          <CreatableSelect
            placeholder={placeholder}
            formatCreateLabel={(input) => `Enter "${input}"`}
            options={options}
            styles={customStyle}
            classNames={customClassNames}
            theme={customTheme}
            components={{
              DropdownIndicator: null,
              IndicatorSeparator: null,
              NoOptionsMessage: (props) => (
                <div
                  {...props.innerProps}
                  className="p-2 text-gray-500 text-center"
                >
                  No suggestions
                </div>
              ),
            }}
            onChange={(e) => {
              setValue(field.name, e);
              field.onChange(e);
            }}
            isLoading={isLoading}
            isClearable
            {...field}
          />
        )}

        {error && <Error>{error}</Error>}
      </div>
    </>
  );
};

Select.defaultProps = {
  label: "",
  sublabel: "",
  placeholder: "Select",
  options: [],
  error: null,
  onChange: null,
  isLoading: false,
  isCreatable: false,
  setValue: () => {},
};

export default Select;
