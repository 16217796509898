import { FaSpinner } from "react-icons/fa";
import { buttonSize, outlineTheme, solidTheme, textTheme } from "./theme";
import clsx from "clsx";

const Button = ({
  children,

  type,
  className,
  disabled,
  onClick,

  isLoading,
  isLoadingRender,
  progress,
  progressLabel,

  sm,
  md,
  lg,

  solid,
  outline,
  text,

  primary,
  secondary,
  danger,
  gray,

  ...rest
}) => {
  const buildClassDefault = {
    btn: "relative inline-flex items-center rounded justify-center whitespace-nowrap align-middle font-semibold duration-300 ease-in-out hover:cursor-pointer",
    btnBody: "flex items-center",
    btnLoadingBody: "absolute flex items-center justify-center z-[2]",
  };

  const buildThemeClass = () => {
    let theme = null;
    if (solid) theme = solidTheme;
    if (outline) theme = outlineTheme;
    if (text) theme = textTheme;

    if (theme) {
      if (disabled) return theme.disabled;
      if (primary) return theme.primary;
      if (secondary) return theme.secondary;
      if (danger) return theme.danger;
      if (gray) return theme.gray;
    }
    return "text-inherit";
  };

  const buildSizeClass = () => {
    if (sm) return buttonSize.sm;
    if (md) return buttonSize.md;
    if (lg) return buttonSize.lg;
    return buttonSize.md;
  };

  const buildClassName = () => {
    if (text) {
      return clsx(
        buildThemeClass(),
        className,
        isLoading && "hover:!cursor-wait",
        disabled && "hover:!cursor-not-allowed",
        "px-2"
      );
    }
    return clsx(
      buildThemeClass(),
      buildSizeClass(),
      className,
      isLoading && "hover:!cursor-wait",
      disabled && "hover:!cursor-not-allowed"
    );
  };

  return (
    <button
      type={type}
      className={clsx(
        buildClassDefault.btn,
        buildClassName(),
        isLoading || disabled
      )}
      onClick={!isLoading && !disabled ? onClick : () => {}}
      disabled={isLoading || disabled}
      {...rest}
    >
      <div
        className={clsx(
          buildClassDefault.btnBody,
          isLoading ? "is-loading text-transparent" : ""
        )}
      >
        {children}
      </div>
      {isLoading && progressLabel === "" && (
        <div className={buildClassDefault.btnLoadingBody}>
          {isLoadingRender()}
        </div>
      )}
      {isLoading && progressLabel !== "" && (
        <div
          className={clsx(buildClassDefault.btnLoadingBody, "animate-pulse")}
        >
          {progressLabel}
        </div>
      )}

      <div
        className={`absolute bg-black h-full left-0 opacity-30 rounded z-[1]`}
        style={{ width: `${progress}%` }}
      ></div>
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  className: "",
  disabled: false,
  onClick: () => {},

  isLoading: false,
  isLoadingRender: () => <FaSpinner className="animate-spin" />,
  progress: null,
  progressLabel: "",

  sm: false,
  md: false,
  lg: false,

  solid: false,
  outline: false,
  text: false,

  primary: false,
  secondary: false,
  danger: false,
};

export default Button;
